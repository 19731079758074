@use "../foundation/variables" as v;
@use "../foundation/functions" as f;
@use "../foundation/mixin" as m;

/*=======================================
  ヘッダー
=======================================*/
#header {
  width: 100%;
  height: v.$header_height;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  @include m.sp {
    height: v.$header_height_sp;
  }
  .header_l {
    z-index: 11;
    .header_logo {
      position: fixed;
      top: v.$body_pd;
      left: v.$body_pd;
      @include m.tab {
        top: clamp(16px, f.svh(3), 30px);
        left: 50%;
        transform: translateX(-50%);
      }
      a {
        display: block;
        width: 100%;
        height: 100%;
      }
      .logo {
        height: auto;
        display: block;
        width: clamp(130px, 25vh, 160px);
        margin: 0 auto;
      }
    }

    // 表示非表示の制御
    #js__header_logo {
      visibility: hidden;
      opacity: 0;
      transition: 0.3s ease;
      pointer-events: none;
      &.active {
        cursor: pointer;
        visibility: visible;
        opacity: 1;
        pointer-events: auto;
      }
      // タブレット以下は常に表示
      @include m.tab {
        cursor: pointer;
        visibility: visible;
        opacity: 1;
        pointer-events: auto;
      }
    }
  }
  .gnav_pc {
    position: fixed;
    top: 240px;
    right: 0;
    transform: translate(38%, 0) rotate(90deg);
    transform-origin: center;
    display: flex;
    justify-content: center;
    font-size: 14px;
    font-weight: v.$fw_medium_500;
    letter-spacing: 0.215em;
    gap: clamp(30px, 7vh, 52px);
    li {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      pointer-events: none;
      a {
        pointer-events: auto;
        position: relative;
        height: 16px;
        &:before {
          content: "";
          position: absolute;
          bottom: -6px;
          left: 0;
          width: 100%;
          height: 1px;
          background-color: v.$letter_primary;
          transform: scale(0, 1);
          transform-origin: right top;
          transition: transform 0.3s;
        }
        &.active {
          &:before {
            transform: scale(1, 1);
            transform-origin: left top;
          }
        }
        @include m.hover_device {
          &:hover {
            &:before {
              transform: scale(1, 1);
              transform-origin: left top;
            }
          }
        }
        img {
          width: auto;
          height: 80%;
        }
      }
    }
    @include m.tab {
      display: none;
    }
  }

  #sp_menu {
    display: none;
    height: calc(f.dvh(100));
    width: 100%;
    transition: all 0.2s ease-in-out;
    background: #fff;
    position: fixed;
    top: 0;
    left: 0;
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    z-index: 15;
    @include m.tab {
      display: block;
    }
    &.active {
      @include m.tab {
        visibility: visible;
        opacity: 1;
        pointer-events: auto;
      }
    }
    .menu_inner {
      visibility: inherit;
      opacity: inherit;
      width: clamp(216px, 100%, 700px);
      padding: clamp(60px, 10vh, 100px) 40px 0 clamp(40px, 10vw, 80px);
      margin: 0 auto;
    }
    .sp_menu_logo {
      display: block;
      margin-bottom: clamp(40px, 6vh, 60px);
      width: clamp(140px, 40vw, 160px);
      height: auto;
    }
    .gnav_sp {
      margin-bottom: clamp(40px, 20vh, 60px);
      display: flex;
      flex-direction: column;
      gap: clamp(30px, 5vh, 40px);
      .link_wrapper {
        a {
          display: block;
          width: auto;
          height: 20px;
          img {
            height: 100%;
            width: auto;
          }
        }
      }
    }
    .x_link {
      display: block;
      width: 24px;
      height: 24px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  #header_toggle {
    // タップできる範囲の設定
    position: fixed;
    top: 0;
    right: 0;
    width: 50px;
    height: 50px;
    border-radius: 100vh;
    cursor: pointer;
    z-index: 20;
    display: none;
    background-color: transparent;
    pointer-events: auto;
    @include m.tab {
      display: block;
    }
    span {
      display: block;
      position: absolute;
      width: 24px;
      height: 2px;
      background-color: v.$letter_primary;
      transition: 0.6s ease-in-out;
    }
  }
  // 線の位置はここで調整
  $header_toggle_top: 16px;
  $header_toggle_top_offset: 8px;
  $header_toggle_right: 14px;
  #header_toggle span:nth-child(1) {
    top: $header_toggle_top;
    right: $header_toggle_right;
  }
  #header_toggle span:nth-child(2) {
    top: calc($header_toggle_top + $header_toggle_top_offset);
    right: $header_toggle_right;
  }
  #header_toggle span:nth-child(3) {
    top: calc($header_toggle_top + ($header_toggle_top_offset * 2));
    right: $header_toggle_right;
  }
  #header_toggle.active span:nth-child(1) {
    width: 28px;
    transform: translate(3px, 8px) rotate(225deg);
  }
  #header_toggle.active span:nth-child(2) {
    transform: translate(50px, 0) scale(0);
    opacity: 0;
  }
  #header_toggle.active span:nth-child(3) {
    width: 28px;
    transform: translate(3px, -8px) rotate(-225deg);
  }
}

/*=======================================
  home
=======================================*/
#p__home {
  font-family: v.$noto_yakuhan;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  $first_view_margin_bottom: f.clamp_prf(280px, 500px); //#conceptのpadding-topと同じ値
  #first_view_home {
    position: relative;
    width: 100%;
    height: 100vh;
    @include m.tab {
      max-height: calc(f.svh(89) - 90px);
      margin-bottom: clamp(0px, calc(60px - 5vh), 50px);
    }
    .copy_tab {
      display: none;
      margin-top: clamp(60px, f.svh(16), 84px);
      font-size: clamp(10px, 3vw, 14px);
      letter-spacing: f.px2em(20, 4);
      text-align: center;
      position: relative;
      z-index: 1;
      @include m.tab {
        display: block;
      }
    }
    .character {
      width: clamp(764px, 73vw, clamp(1140px, 50vw, 1800px));
      max-width: 130vh;
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-80%, -44%);
      img {
        width: 100%;
      }
      @include m.tab {
        width: clamp(230px, 176vmin, 900px);
        top: clamp(-40px, f.svh(-4), -0px);
        left: 50%;
        transform: translate(-50.5%, 0);
        img {
          max-height: f.svh(89);
          object-fit: contain;
        }
      }
    }
    .brand_visual {
      width: clamp(370px, 31vw, clamp(588px, 50vw, 880px));
      max-width: 70vh;
      display: flex;
      flex-direction: column;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(0, -50%);
      z-index: 1;
      .logo {
        width: 100%;
      }
      .copy {
        margin-top: 20px;
        text-align: center;
        color: v.$letter_primary;
        font-size: clamp(20px, 1vw, 28px);
        font-weight: v.$fw_regular_400;
        line-height: normal;
        letter-spacing: f.px2em(20, 4);
      }
      @include m.tab {
        display: none;
      }
      @include m.sp {
        display: none;
      }
    }
    .scrolldown_line {
      position: absolute;
      top: 91vh;
      left: 50%;
      transform: translate(-50%, 0);
      width: 1px;
      height: calc(9vh + $first_view_margin_bottom - 100px);
      background-color: #334444;
      z-index: 1;
    }
    .scrolldown_image {
      width: 36px;
      height: auto;
      position: absolute;
      top: 91vh;
      left: calc(50% + 10px);
      z-index: 1;
    }
  }
  .scrolldown_tab {
    position: relative;
    margin-top: clamp(-60px, calc(140px - 20vh), 20px);
    opacity: 0;
    filter: blur(10px);
    &.loaded {
      opacity: 1;
      filter: blur(0);
      transition:
        opacity 0.5s ease-in-out,
        filter 1.7s ease-in-out;
      transition-delay: 0.4s;
    }
    .scrolldown_line {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, 0);
      width: 1px;
      height: calc(9vh + $first_view_margin_bottom - 110px);
      background-color: #334444;
      z-index: 1;
    }
    .scrolldown_image {
      position: absolute;
      top: 0;
      left: calc(50% + 10px);
      width: 36px;
      height: auto;
      z-index: 1;
    }
  }
  #concept {
    // *** メニューをクリックした際、上部に余白が付くよう100px分調整
    margin-top: calc($first_view_margin_bottom - 100px); // ***
    padding-top: 100px; // ***
    padding-bottom: f.clamp_prf(114px, 194px);
    text-align: center;
    border-bottom: v.$border_primary;
    .concept_head {
      font-size: clamp(18px, 2vw, 31px);
      line-height: 178.3%;
      letter-spacing: 0.2em;
    }
    .c__common_text {
      margin: f.clamp_prf(18px, 50px) auto 0;
    }
    @include m.sp {
      .concept_head {
        letter-spacing: 0.1em;
      }
      .u__ib {
        display: inline;
      }
    }
  }
  #feature {
    padding-top: f.clamp_prf(94px, 158px);
    padding-bottom: f.clamp_prf(110px, 200px);
    border-bottom: v.$border_primary;
    .c__head_primary {
      text-align: center;
      @include m.sp {
        text-align: left;
      }
    }
    .head_description {
      margin: f.clamp_prf(16px, 24px) auto 0;
      text-align: center;
      margin-top: 24px;
      @include m.sp {
        margin-top: 16px;
        text-align: left;
      }
    }
    .feature_details {
      margin-top: f.clamp_prf(48px, 120px);
      display: flex;
      flex-direction: column;
      gap: f.clamp_prf(60px, 80px);
      @include m.tab {
        align-items: center;
      }
      .detail {
        display: flex;
        gap: clamp(28px, 3vw, 70px);
        justify-content: center;
        align-items: flex-start;
        @include m.tab {
          width: 100%;
          flex-direction: column;
          align-items: center;
        }
        img {
          width: 400px;
          max-width: 500px;
          flex-shrink: 0;
          height: auto;
          object-fit: contain;
          @include m.tab {
            width: 100%;
          }
        }
        .description {
          display: flex;
          flex-direction: column;
          gap: f.clamp_prf(18px, 22px);
          flex: 1;
          .detail_head {
            font-size: f.clamp_prf(18px, 24px);
            letter-spacing: f.px2em(24, 4.8);
            line-height: normal;
          }
          @include m.tab {
            width: 100%;
            max-width: 500px;
          }
        }
      }
    }
    .service_link {
      margin: f.clamp_prf(80px, 140px) auto 0;
    }
  }
  #recruit {
    padding-top: f.clamp_prf(130px, 190px);
    padding-bottom: f.clamp_prf(170px, 220px);
    border-bottom: v.$border_primary;
    .c__head_primary {
      text-align: center;
      @include m.sp {
        text-align: left;
      }
    }
    .c__common_text {
      margin-top: f.clamp_prf(16px, 24px);
      text-align: center;
      @include m.sp {
        text-align: left;
      }
    }
    .c__primary_button {
      margin: f.clamp_prf(38px, 64px) auto 0;
    }
  }
  #company {
    padding-top: f.clamp_prf(160px, 160px);
    padding-bottom: f.clamp_prf(170px, 186px);
    border-bottom: v.$border_primary;
    @include m.sp {
      border-bottom: none;
    }
    .ceo_profile {
      display: flex;
      gap: f.clamp_prf(50px, 54px);
      align-items: flex-start;
      @include m.tab {
        flex-direction: column;
        align-items: center;
      }
      img {
        display: block;
        width: clamp(126px, 26vw, 240px);
        object-fit: contain;
      }
      a {
        text-decoration: underline;
      }
      .description {
        display: flex;
        flex-direction: column;
        gap: f.clamp_prf(12px, 40px);
        .detail_head {
          font-size: f.clamp_prf(18px, 24px);
          line-height: 200%;
          letter-spacing: f.px2em(24, 1.12);
          .small {
            font-size: f.clamp_prf(12px, 16px);
          }
        }
        .c__common_text {
          letter-spacing: 0.06em;
        }
      }
    }
    .company_summary {
      margin-top: f.clamp_prf(78px, 174px);
      .c__head_primary {
        text-align: center;
      }
      .company_summary_table {
        margin: f.clamp_prf(16px, 48px) auto 0;
        max-width: 534px;
      }
    }
  }
}
#p__recruit {
  font-family: v.$noto_yakuhan;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  #first_view_recruit {
    position: relative;
    width: 100%;
    height: 100vh;
    @include m.tab {
      max-height: calc(f.svh(89) - 90px);
    }
    .recruit_heading_tab {
      display: none;
      margin-top: 86px;
      margin-top: clamp(60px, f.svh(13), 84px);
      font-size: 16px;
      letter-spacing: f.px2em(20, 4);
      text-align: center;
      position: relative;
      z-index: 1;
      @include m.tab {
        display: block;
      }
    }
    .character {
      width: clamp(764px, 73vw, clamp(1140px, 50vw, 1800px));
      max-width: 130vh;
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-80%, -44%);
      img {
        width: 100%;
      }
      @include m.tab {
        width: clamp(230px, 176vmin, 900px);
        top: clamp(-20px, f.svh(-4), -0px);
        left: 50%;
        transform: translate(-50.5%, 0);
        img {
          max-height: f.svh(89);
          object-fit: contain;
        }
      }
    }
    .recruit_fv_r {
      width: clamp(370px, 37vw, 588px);
      display: flex;
      flex-direction: column;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(0, -30%);
      z-index: 1;
      @include m.tab {
        display: none;
      }
    }
    .recruit_heading {
      color: v.$letter_primary;
      font-weight: v.$fw_regular_400;
      letter-spacing: f.px2em(66, 13.2);
      .green {
        color: v.$letter_secondary;
      }
    }
    .recruit_heading_pc {
      font-size: clamp(40px, 3vw, 60px);
    }
    .recruit_fv_description {
      padding-top: f.clamp_prf(40px, 66px);
      font-size: f.clamp_prf(15px, 17px);
      line-height: 215%;
      font-weight: v.$fw_regular_400;
      letter-spacing: f.px2em(18, 1.8);
    }
  }

  .recruit_fv_description_tab {
    display: none;
    position: relative;
    text-align: center;
    font-size: 12px;
    line-height: 220%;
    font-weight: v.$fw_regular_400;
    letter-spacing: 0.05em;
    z-index: 1;
    margin-top: clamp(-60px, 170px - 20vh, 20px);
    max-width: 460px;
    @include m.tab {
      display: block;
    }
  }

  #summary {
    padding-top: 80px;
    padding-bottom: f.clamp_prf(106px, 190px);
    border-bottom: v.$border_primary;
    z-index: 1;
    position: relative;
    .company_summary {
      .c__head_primary {
        text-align: center;
        font-weight: v.$fw_regular_400;
        @include m.sp {
          text-align: left;
          font-weight: v.$fw_medium_500;
        }
      }
      .company_summary_table {
        margin: f.clamp_prf(16px, 36px) auto 0;
        max-width: 920px;
        .bold {
          font-weight: v.$fw_bold_700;
        }
        .row {
          padding: f.clamp_prf(18px, 25px) 0;
          min-height: f.clamp_prf(56px, 80px);
          align-items: center;
          .head {
            max-width: f.clamp_prf(108px, 240px);
            line-height: 166.667%;
          }
          .content {
            line-height: 162.5%;
          }
          .c__unordered_list {
            width: 100%;
            li {
              line-height: 162.5%;
              overflow-wrap: break-word;
              word-break: break-word;
              text-align: justify;
            }
          }
        }
      }
    }
  }
  #application_method {
    padding-top: f.clamp_prf(76px, 198px);
    padding-bottom: f.clamp_prf(86px, 190px);
    border-bottom: v.$border_primary;
    text-align: center;
    .c__head_primary {
      font-weight: v.$fw_regular_400;
      line-height: 1;
      @include m.sp {
        text-align: left;
        font-weight: v.$fw_medium_500;
      }
    }
    .c__common_text {
      letter-spacing: 0;
      @include m.sp {
        text-align: left;
      }
    }
    .prompt {
      margin-top: 16px;
    }
    .closed {
      margin-top: f.clamp_prf(20px, 34px);
    }
    .contact_info {
      padding: f.clamp_prf(24px, 48px) f.clamp_prf(20px, 40px) f.clamp_prf(20px, 40px);
      margin: f.clamp_prf(26px, 50px) auto 0;
      width: 100%;
      max-width: 720px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: f.clamp_prf(14px, 20px);
      border: v.$border_primary;
      .mail {
        font-size: f.clamp_prf(24px, 32px);
        font-weight: v.$fw_regular_400;
      }
      .annotation {
        font-size: f.clamp_prf(12px, 18px);
        font-weight: v.$fw_regular_400;
        line-height: 220%;
        letter-spacing: f.px2em(18, 0.72);
        text-decoration: underline;
        span {
          text-decoration: underline;
        }
      }
    }
  }
  #flow {
    padding-top: f.clamp_prf(102px, 178px);
    padding-bottom: f.clamp_prf(100px, 246px);
    border-bottom: v.$border_primary;
    .c__head_primary {
      text-align: center;
      font-weight: v.$fw_regular_400;
      @include m.sp {
        text-align: left;
        font-weight: v.$fw_medium_500;
      }
    }
    .flow_table {
      width: 100%;
      max-width: clamp(336px, 49vw, 620px);
      margin: f.clamp_prf(30px, 70px) auto 0;
      display: flex;
      flex-direction: column;
      gap: f.clamp_prf(32px, 48px);
      @include m.sp {
        margin-left: 0;
        max-width: 400px;
      }
      .row {
        display: flex;
        gap: f.clamp_prf(20px, 62px);
        align-items: center;
        height: 72px;
        @include m.sp {
          height: auto;
          align-items: baseline;
        }
        .head {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          max-width: 72px;
          height: 72px;
          border-right: solid 1px v.$letter_primary;
          img {
            width: 14px;
            height: 14px;
          }
          @include m.sp {
            width: auto;
            max-width: 44px;
            height: auto;
            border-right: none;
            justify-content: flex-start;
          }
        }
        .content {
          line-height: 131.25%;
          width: 100%;
          .content_head {
            display: block;
            font-size: f.clamp_prf(18px, 24px);
            @include m.sp {
              font-weight: v.$fw_medium_500;
            }
          }
          .content_description {
            display: inline-block;
            font-size: f.clamp_prf(12px, 16px);
            // 最初のcontent_description
            &:nth-child(2) {
              margin-top: 12px;
            }
          }
        }
      }
    }
  }
}
#p__error {
  font-family: v.$noto_yakuhan;
  width: 100%;
  height: 100vh;
  max-width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .c__head_primary {
    font-size: f.clamp_prf(18px, 20px);
    margin: 0 auto;
    text-align: center;
  }
  .c__primary_button {
    margin: f.clamp_prf(30px, 40px) auto 0;
  }
}

#footer {
  font-size: f.clamp_prf(11px, 12px);
  &.footer_home {
    padding: 118px 0 30px;
    @include m.sp {
      padding: 0 0 60px;
    }
  }
  &.footer_recruit {
    padding: 130px 0 30px;
    @include m.sp {
      padding: 130px 0 60px;
    }
  }
  .logo_link {
    display: block;
    width: 168px;
    margin: 0 auto;
    transition: 0.3s;
    img {
      width: 100%;
    }
    @include m.hover_device {
      &:hover {
        opacity: 0.8;
      }
    }
    @include m.sp {
      display: none;
    }
  }
  .company_name {
    text-align: center;
    margin-top: 30px;
    font-size: 1em;
  }
  .address {
    text-align: center;
    margin-top: f.clamp_prf(0px, 5px);
    line-height: 162.5%;
    font-size: 1em;
  }
  .x_link {
    display: block;
    width: 26px;
    margin: f.clamp_prf(20px, 32px) auto 0;
    transition: 0.3s;
    img {
      width: 100%;
    }
    @include m.hover_device {
      &:hover {
        opacity: 0.6;
      }
    }
  }
  .copyrights {
    display: block;
    text-align: center;
    margin-top: f.clamp_prf(20px, 26px);
    font-size: 8px;
    font-style: normal;
    font-weight: 400;
    line-height: 187.5%;
    letter-spacing: 0.8px;
  }
}
#pagetop_button {
  position: fixed;
  bottom: 0px;
  right: 20px;
  z-index: 9;
  width: f.clamp_prf(46px, 46px);
  height: 60px;
  opacity: 0;
  transition: 0.2s ease;
  pointer-events: none;
  &.active {
    bottom: 30px;
    opacity: 1;
    pointer-events: auto;
    cursor: pointer;
  }
  .img_wrapper {
    position: relative;
    width: 100%;
    height: 100%;
  }
  .arrow {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: auto;
    cursor: pointer;
    transition: 0.2s ease;
  }
  .letter {
    position: absolute;
    bottom: 0;
    right: 0;
    pointer-events: none;
    width: 28%;
    height: auto;
  }
  @include m.hover_device {
    &:hover {
      .arrow {
        bottom: 10px;
      }
    }
  }
}
