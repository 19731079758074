// 色系
$letter_primary: #393939;
$letter_secondary: #30b396;
// $bg_primary: #111111;
$gray: #929292;
// $darkgray: #C6C6C6;
$selection_bg: $letter_secondary;
$selection_text_color: #fff;
$selection_img_bg: #00000020;

// よく使うボーダー
$border_primary: 1px solid #D9D9D9;

// フォント系
$noto_yakuhan: "YakuHanJP", "Noto Sans JP", 'Helvetica', 'arial', sans-serif; //light: 300, regular: 400, medium: 500, bold: 700
$noto: "Noto Sans JP", 'Helvetica', 'arial', sans-serif; //light: 300, regular: 400, medium: 500, bold: 700
$fw_exlight_200: 200;
$fw_light_300: 300;
$fw_regular_400: 400;
$fw_medium_500: 500;
$fw_semibold_600: 600;
$fw_bold_700: 700;

//ヘッダーフッターの高さ
$header_height: 150px;
$header_height_sp: clamp(40px, 10vh, 80px);

// 縦横比を固定する場合に使用
$ratio_16_9: 56.25%;
$ratio_4_3: 75%;
$ratio_3_2: 66.67%;
$ratio_1_1: 100%;

// メディアクエリー用
$pc_min: 1100px; // pcコンテンツ幅
$tab_max: 920px;
$sp_max: 712px;
$layout_min: 320px;
$body_pd: clamp(20px, 6vw, 30px);

// vw変換用(デザインデータキャンバス幅pxに合わせる)
$vp_basis_pc: 1920;
$vp_basis_sp: 375;

/*=======================================
  z-index管理
=========================================

----- 20 -----
#header_toggle

----- 15 -----
#sp_menu

----- 11 -----
.header_l

----- 10 -----
#header

----- 9 -----
#pagetop_button

*/