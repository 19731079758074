@use "../foundation/variables" as v;
@use "../foundation/functions" as f;
@use "../foundation/mixin" as m;

/*==============================
文字種
==============================*/
.c__common_text {
  color: v.$letter_primary;
  font-family: v.$noto;
  font-size: f.clamp_prf(12px, 16px);
  font-weight: v.$fw_regular_400;
  line-height: 220%;
  letter-spacing: f.px2em(16, 1.6);
}

/*==============================
見出し系
==============================*/
.c__head_primary {
  font-family: v.$noto;
  font-size: f.clamp_prf(26px, 32px);
  font-weight: v.$fw_medium_500;
  letter-spacing: f.px2em(26, 6.4);
  line-height: 194.8%; /* 62.336px */
  margin: 0 auto;
}

/*==============================
ボタン系
==============================*/
.c__primary_button {
  width: f.clamp_prf(240px, 320px);
  height: f.clamp_prf(40px, 64px);
  color: #fff;
  background-color: v.$letter_secondary;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: f.clamp_prf(14px, 20px);
  border-radius: 4px;
  letter-spacing: f.px2em(18, 2);
  transition: 0.3s;
  &:link {
    color: #fff;
  }
  &:visited {
    color: #fff;
  }
  &:hover {
    color: #fff;
  }
  &:active {
    color: #fff;
  }
  @include m.hover_device {
    &:hover {
      background-color: #009d7a;
    }
  }
}

/*==============================
  装飾系
==============================*/
.c__unordered_list {
  display: flex;
  flex-direction: column;
  gap: f.px2em(16, 10);
  li {
    margin-left: 1em;
    position: relative;
    &:before {
      content: "";
      position: absolute;
      top: 0.7em;
      left: -0.9em;
      width: 4px;
      height: 4px;
      background-color: v.$letter_primary;
      border-radius: 100vh;
    }
  }
}

/*==============================
テーブル
==============================*/
.c__primary_table {
  max-width: 100%;
  font-size: f.clamp_prf(12px, 16px);
  font-weight: v.$fw_regular_400;
  line-height: 212.5%;
  .row {
    border-top: v.$border_primary;
    display: flex;
    padding: f.clamp_prf(20px, 34px) 0;
    align-items: center;
    &:last-child {
      border-bottom: v.$border_primary;
    }
    .head {
      width: 100%;
      max-width: f.clamp_prf(100px, 140px);
    }
    .content {
      line-height: 131.25%;
      width: 100%;
      a {
        color: v.$letter_primary;
        text-decoration: underline;
      }
    }
  }
}

/*=======================================
  アニメーション系
=======================================*/
.c__js_fade {
  transform: translate(0, 40px);
  opacity: 0;
  &.active {
    transform: translate(0, 0) scale(100%);
    opacity: 1;
    transition: 0.3s ease;
  }
}
// ディレイを付けたい場合
// 親要素に c__js_fade_delay を付け、子要素に js__delay_child delay2 のようなクラスを付ける
.c__js_fade_delay {
  .js__delay_child {
    transform: translate(0, 40px);
    opacity: 0;
  }
  &.active {
    .js__delay_child {
      transform: translate(0, 0) scale(100%);
      opacity: 1;
      transition: 0.3s ease;
      @include m.tran_delay(0.2);
    }
  }
}
// ブラーを付けたい場合
.c__js_blur {
  opacity: 0;
  filter: blur(10px);
  &.active {
    opacity: 1;
    filter: blur(0px);
    transition: all 0.5s ease-in-out;
  }
}
// ブラーかつディレイを付けたい場合
// 親要素に c__js_blur_delay を付け、子要素に js__delay_child delay2 のようなクラスを付ける
.c__js_blur_delay {
  .js__delay_child {
    opacity: 0;
    filter: blur(10px);
  }
  &.active {
    .js__delay_child {
      opacity: 1;
      filter: blur(0px);
      transition:
        opacity 0.5s ease-in-out,
        filter 1.0s ease-in-out;
      @include m.tran_delay(0.35);
    }
  }
}

.js__transition {
  .js__transition_child {
    // .js__transition_child .delay3 のようにクラスを指定すると0.2 * 3 = 0.6秒遅延
    @include m.tran_delay(0.2);
  }
}
.js__animation {
  .js__animation_child {
    // .js__animation_child .delay3 のようにクラスを指定すると0.2 * 3 = 0.6秒遅延
    @include m.anim_delay(0.2);
  }
}
