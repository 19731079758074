@use "../foundation/functions" as f;
@use "../foundation/variables" as v;

/*=======================================
  ページ制御系
=======================================*/
@mixin pc {
  @media (min-width: (v.$pc_min)) {
    @content;
  }
}
@mixin tab {
  @media (max-width: (v.$tab_max)) {
    @content;
  }
}
@mixin sp {
  @media (max-width: (v.$sp_max)) {
    @content;
  }
}
@mixin hover_device {
  @media (any-hover: hover) {
    @content;
  }
}
@mixin touch_device {
  @media (any-hover: none) {
    @content;
  }
}
//縦長
@mixin portrait {
  @media (orientation: portrait) {
    @content;
  }
}
//横長
@mixin landscape {
  @media (orientation: landscape) {
    @content;
  }
}

@mixin content_width($width: v.$pc_min) {
  max-width: calc($width + v.$body_pd + v.$body_pd);
  width: 100%;
  padding-left: v.$body_pd;
  padding-right: v.$body_pd;
  margin-left: auto;
  margin-right: auto;
}

/*===================================================
  アニメーションディレイ
  子要素のアニメーションにディレイをかけられる
===================================================*/
@mixin anim_delay($magnification: 0.05) {
  @for $i from 0 through 12 {
    &.delay#{$i} {
      animation-delay: #{$i * $magnification}s;
    }
  }
}
@mixin tran_delay($magnification: 0.05) {
  @for $i from 0 through 12 {
    &.delay#{$i} {
      transition-delay: #{$i * $magnification}s;
    }
  }
}
